import React from "react";
import styled from "styled-components";

export const TeamSection = styled.section`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 80px 0 0 0;

  @media (min-width: 1500px) {
    position: absolute;
  }
`;

export const TeamContainer = styled.div`
  width: 100%;
  height: 100%;
  /* font-size: 100px; */
  /* display: flex;

  justify-content: center;
  align-items: center; */

  @media (min-width: 1200px) {
    /* height: 100vh; */
    margin: 80px 0 0;
  }

  div {
    /* border-radius: 10%; */
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    /* height: 300px; */
    border-radius: 10% 10% 10% 10%;

    @media (min-width: 1200px) {
      width: 350px;
    }

    @media (min-width: 1600px) {
      width: 400px;
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    /* height: 100%; */
    border-radius: 10%;

    @media (min-width: 100%) {
      height: 100%;
    }
  }
`;

export const MemberContainer = styled.div`
  position: relative;
  width: 300px;
  height: 500px;
  border-radius: 10%;
  display: flex;
  flex-direction: column;

  @media (min-width: 1200px) {
    width: 350px;
    height: 600px;
  }

  @media (min-width: 1600px) {
    width: 400px;
    height: 750px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    width: 100px;
    display: flex;
    justify-content: center;
    padding: 5px;

    &:last-of-type {
      justify-content: end;
    }
    a {
      margin: 0 auto;
      text-align: right;
      width: 30px;
      height: 30px;
    }
  }
`;
