import React from "react";
import styled from "styled-components";
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";
import { Button } from "../../atoms/StyledButton/StyledButton";
import { Header } from "../../atoms/HeaderText/HeaderText";
import {
  SectionContainer,
  FormWrapper,
  Input,
  InputContainer,
  Placeholder,
  ButtonContainer,
  Cut,
  ErrorContainer,
} from "./Contact.styles";

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const sendEmail = (formData) => {
    console.log(formData);
    emailjs
      .send(
        "service_pujr1m5",
        "template_dql7x43",
        formData,
        "MzaeL-pV-eLoX1irr"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    reset();
  };
  return (
    <SectionContainer>
      <Header>KONTAKT</Header>
      <FormWrapper>
        <form className="contact-form" onSubmit={handleSubmit(sendEmail)}>
          <InputContainer>
            {/* <Cut /> */}
            <Input name="name" {...register("name")} placeholder=" " />
            <Cut />
            <label for="name">Imię</label>
          </InputContainer>
          <InputContainer>
            {/* <Cut /> */}
            <Input
              type="email"
              name="email"
              {...register("email")}
              placeholder=" "
            />
            <Cut />
            <label for="email">Email</label>
          </InputContainer>
          <InputContainer>
            <textarea
              id="message"
              rows="3"
              placeholder=" "
              name="message"
              {...register("message", { required: true })}
            ></textarea>
            <Cut />
            <label for="message">Wiadomość</label>
          </InputContainer>
          <div>
            <ButtonContainer>
              <Button type="submit" value="SEND MESSAGE">
                Wyślij
              </Button>
            </ButtonContainer>
          </div>
          {errors.message && (
            <ErrorContainer style={{ color: "red" }}>
              Uzupełnij wszystkie pola
            </ErrorContainer>
          )}
        </form>
      </FormWrapper>
    </SectionContainer>
  );
};

export default Contact;
