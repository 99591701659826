import styled from "styled-components";
import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Header } from "../../atoms/HeaderText/HeaderText";
import { EffectCoverflow, Pagination } from "swiper/modules";
import Loading from "../../organisms/Loading/Loading";
import {
  TeamContainer,
  TeamSection,
  MemberContainer,
  InfoContainer,
} from "./Team.styles";

const Team = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await fetch("https://graphql.datocms.com/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
        body: JSON.stringify({
          query:
            "{ allTeams { id name job description instagram image {id url} booksy } }",
        }),
      });

      const myData = await response.json();

      setData(myData.data.allTeams.reverse());
      setLoading(false);
    } catch (error) {
      console.error("Błąd pobierania danych:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <TeamSection>
      <h1>Zespół</h1>
      <TeamContainer>
        <Swiper
          effect={"coverflow"}
          // grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper"
        >
          {data.map((member) => (
            <SwiperSlide key={member.id}>
              <MemberContainer>
                <a href={member.booksy} target="_blank">
                  <img src={member.image.url} />
                </a>
                <InfoContainer>
                  <div>
                    <p style={{ fontWeight: "900", fontSize: "20px" }}>
                      {member.name}
                    </p>
                  </div>
                  <div>
                    <p>{member.job}</p>
                  </div>
                  <div>
                    <a href={member.instagram} target="_blank">
                      <FontAwesomeIcon
                        style={{ fontSize: "28px" }}
                        icon={faInstagram}
                      />
                    </a>
                  </div>
                </InfoContainer>
              </MemberContainer>
            </SwiperSlide>
          ))}
        </Swiper>
      </TeamContainer>
    </TeamSection>
  );
};

export default Team;
